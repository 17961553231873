
/* eslint-disable no-undef */
import { useRouter } from "vue-router";
import {
  defineComponent,
  reactive,
  ref,
  onMounted,
  computed,
} from "vue";
import { GeoFire } from "geofire";
import {
  IonContent,
  IonImg,
  IonPage,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonCard,
  IonList,
  IonListHeader,
  IonText,
  IonItem,
} from "@ionic/vue";
import {
  arrowForwardOutline,
  arrowBackSharp,
  mailOutline,
  callOutline,
  locationOutline,
  linkOutline,
} from "ionicons/icons";

import DlvrInput from "@/components/DlvrInput.vue";
import TextLoader from "@/components/TextLoader.vue";
import { db } from "@/lib/firebase";
import { getConfig } from "@/lib/constants";
import { Merchant } from "@/types/merchant";

import AgentDetails from "./AgentDetails.vue";
import CompanyPreview from "./CompanyPreview.vue";

const leadsLocationRef = db.ref("leads-location-data");
const geoFire = new GeoFire(leadsLocationRef);

export default defineComponent({
  components: {
    IonContent,
    IonImg,
    IonPage,
    IonButton,
    IonCol,
    IonGrid,
    IonRow,
    IonCard,
    IonList,
    IonListHeader,
    IonText,
    IonItem,
    AgentDetails,
    CompanyPreview,
    DlvrInput,
    TextLoader,
  },
  setup() {
    const radius = 50; // KM
    const pickupAddress = ref("");
    const deliveryAddress = ref("");
    const mapRef = ref<any>(null);
    const previewVisible = ref<boolean>(false);
    const searchActive = ref<boolean>(false);
    const loadingIndicator = ref<boolean>(false);
    const selectedMerchant = ref<Merchant | null>(null);
    const activeLocations = reactive<{ [key: string]: any }>({});
    // eslint-disable-next-line no-undef
    const fullAddress = ref<google.maps.places.PlaceResult | null>(
      null,
    );
    let userMarker = ref<google.maps.Marker | null>(null);
    let activePath = ref<google.maps.Polyline | null>(null);
    let loadedMerchants = ref<Merchant[]>([]);
    let lastLoad: number | null = null;
    let loadingTimeout: number | null = null;
    const router = useRouter();

    let map: google.maps.Map;

    const hidePreview = () => {
      previewVisible.value = false;
      selectedMerchant.value = null;
    };

    const clearMap = () => {
      Object.keys(activeLocations).forEach(activeLocationKey => {
        activeLocations[activeLocationKey].marker.setMap(null);
        delete activeLocations[activeLocationKey];
      });
    };

    const checkLoaded = () => {
      if (lastLoad) return Date.now() - lastLoad > 1000;
    };

    const onLoaded = async () => {
      await getMerchantByIds(Object.keys(activeLocations));
      loadingIndicator.value = false;
    };

    const setLoadingTimeout = () => {
      loadingTimeout = setTimeout(() => {
        if (checkLoaded()) {
          if (loadingTimeout) clearTimeout(loadingTimeout);
          lastLoad = null;
          onLoaded();
        } else {
          setLoadingTimeout();
        }
      }, 2000);
    };

    const drawActivePath = (location: [number, number]) => {
      if (activePath.value) {
        activePath.value?.setMap(null);
      }
      if (userMarker && selectedMerchant) {
        console.log("drawing");
        const origin = userMarker.value?.getPosition();
        const [destinationLat, destinationLng] = location;

        if (origin && destinationLat && destinationLng) {
          const pathCoordinates = [
            { lat: origin?.lat(), lng: origin.lng() },
            { lat: destinationLat, lng: destinationLng },
          ];
          activePath.value = new google.maps.Polyline({
            path: pathCoordinates,
            geodesic: true,
            strokeColor: "#FF0000",
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map,
          });
        }
      }
    };

    const findMerchantsCloseToAddress = () => {
      clearMap();
      userMarker.value?.setMap(null);
      loadingIndicator.value = true;
      if (!fullAddress.value || !fullAddress.value.geometry) return;

      searchActive.value = true;
      const [lat, lng] = [
        fullAddress.value.geometry.location.lat(),
        fullAddress.value.geometry.location.lng(),
      ];
      map.setCenter({ lat, lng });
      userMarker.value = new google.maps.Marker({
        position: { lat, lng },
        map,
      });
      const geoQuery = geoFire.query({
        center: [lat, lng],
        radius,
      });

      setLoadingTimeout();

      geoQuery.on("key_entered", function(
        key: string,
        location: [number, number],
        distance: number,
      ) {
        const [lat, lng] = location;
        const marker = new google.maps.Marker({
          position: { lat, lng },
          map,
          icon:
            "https://firebasestorage.googleapis.com/v0/b/dlvr-automate.appspot.com/o/agent-bike.png?alt=media&token=c0258c77-f7c1-48b3-9a6c-8ea136ebd51c",
        });
        activeLocations[key] = {
          location,
          distance: distance.toFixed(2),
          marker,
        };
        lastLoad = Date.now();
        if (!loadingTimeout) {
          setLoadingTimeout();
        }
      });
    };

    const initMap = () => {
      const element = mapRef.value.parentElement.querySelector(
        "#map",
      );
      map = new google.maps.Map(element, {
        zoom: 15,
        center: new google.maps.LatLng(6.5244, 3.3792),
        mapTypeId: "terrain",
      });
    };

    const useCurrentLocation = () => {
      return 1;
    };

    const getMerchantByIds = async (ids: string[]) => {
      const allIds = ids.map(id => `id_in=${id}`).join("&");
      const requestUrl = `${
        getConfig().BASE_URL
      }/merchant-profiles?${allIds}`;
      const response = await fetch(requestUrl);
      if (response.ok) loadedMerchants.value = await response.json();
    };

    const setSelectedMerchant = (id: string) => {
      previewVisible.value = true;
      selectedMerchant.value =
        loadedMerchants.value.find(merchant => merchant.id === id) ||
        null;
      const selectedMerchantLocation = activeLocations[id].location;
      drawActivePath(selectedMerchantLocation);
    };

    onMounted(async () => {
      // push to login
      await router.push(`/login`);
      // initMap();
    });

    const sortMerchantsList = (merchantsList: Merchant[]) => {
      const loadedMerchantsCopy = [...merchantsList];
      loadedMerchantsCopy.sort((merchantA, merchantB) =>
        merchantA.id && merchantB.id
          ? activeLocations[merchantA.id].distance -
            activeLocations[merchantB.id].distance
          : 0,
      );
      return loadedMerchantsCopy;
    };

    const sortedMerchantInformation = computed(() =>
      sortMerchantsList(loadedMerchants.value),
    );

    // const loadMerchantInformation = () => {};

    return {
      deliveryAddress,
      pickupAddress,
      fullAddress,
      mapRef,
      arrowForwardOutline,
      arrowBackSharp,
      mailOutline,
      callOutline,
      locationOutline,
      linkOutline,
      previewVisible,
      hidePreview,
      findMerchantsCloseToAddress,
      useCurrentLocation,
      activeLocations,
      searchActive,
      loadingIndicator,
      sortedMerchantInformation,
      selectedMerchant,
      setSelectedMerchant,
    };
  },
});
