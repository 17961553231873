
import { defineComponent } from "vue";
import { IonSpinner, IonText } from "@ionic/vue";

export default defineComponent({
  components: {
    IonSpinner,
    IonText,
  },
  props: {
    loadingText: String,
  },
});
