
/* eslint-disable no-undef */
import { defineComponent, PropType } from "vue";
import { IonCard, IonImg, IonIcon, IonButton } from "@ionic/vue";
import {
  arrowForwardOutline,
  arrowBackSharp,
  mailOutline,
  callOutline,
  locationOutline,
  linkOutline,
} from "ionicons/icons";

import { Merchant } from "@/types/merchant";

import CompanyPreview from "./CompanyPreview.vue";

export default defineComponent({
  components: {
    IonCard,
    IonImg,
    IonIcon,
    IonButton,
    CompanyPreview,
  },
  props: {
    merchant: Object as PropType<Merchant>,
    distance: Number,
  },
  setup() {
    return {
      arrowForwardOutline,
      arrowBackSharp,
      mailOutline,
      callOutline,
      locationOutline,
      linkOutline,
    };
  },
});
