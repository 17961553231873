
import { defineComponent, PropType } from "vue";
import {
  IonImg,
  IonButton,
  IonCol,
  IonGrid,
  IonRow,
  IonText,
} from "@ionic/vue";
import {
  arrowForwardOutline,
  arrowBackSharp,
  mailOutline,
  callOutline,
  locationOutline,
  linkOutline,
} from "ionicons/icons";

import { Merchant } from "@/types/merchant";

export default defineComponent({
  components: {
    IonImg,
    IonButton,
    IonCol,
    IonGrid,
    IonRow,
    IonText,
  },
  props: {
    merchant: Object as PropType<Merchant>,
    distance: String,
    expanded: Boolean,
  },
  setup() {
    return {
      arrowForwardOutline,
      arrowBackSharp,
      mailOutline,
      callOutline,
      locationOutline,
      linkOutline,
    };
  },
});
