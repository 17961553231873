import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Transition as _Transition, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35a7c363"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "image-header" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_company_preview = _resolveComponent("company-preview")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_Transition, { name: "slide-fade" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_card, { class: "company-info" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_ion_button, {
              class: "back-button",
              fill: "clear",
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_icon, { icon: _ctx.arrowBackSharp }, null, 8, ["icon"])
              ]),
              _: 1
            }),
            _createVNode(_component_ion_img, {
              class: "logo-preview",
              src: "/assets/dlvr-blue-app-icon.png"
            })
          ]),
          _createVNode(_component_company_preview, {
            merchant: _ctx.merchant,
            distance: _ctx.distance,
            expanded: ""
          }, null, 8, ["merchant", "distance"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}